<template>
  <div>
    <b-modal
      id="whats-1"
      title="Certificado gerado com sucesso!"
      @ok="sendByWhatsapp"
      @cancel="$router.push('/dashboard')"
      ok-title="sim"
      cancel-title="não"
    >
      <p>
        Enviamos instruções para o email cadastrado. Quer enviar também por
        WhatsApp?
      </p>
    </b-modal>
    <div v-show="loading" class="text-center">
      <b-spinner
        style="width: 4rem; height: 4rem; margin-top: 30px"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div style="min-height: 5000px" v-show="!loading">
      <ProductPanel />
      <button
        v-if="type"
        class="btn btn-block py-4 mt-6"
        style="background: #dce1e5"
        @click="reset"
      >
        REDEFINIR
      </button>
      <ProductTypeManual />
      <ProductTechnologyManual
        v-if="type && !technology && order.type_id !== 7"
        @input="setColorStatus"
      />
      <ProductColor v-if="technology && !color" />
      <ProductIndex v-if="!index && technology && type" />
      <ProductIndex v-if="!index && technology && type && color" />
      <ProductTreatment v-if="index && !treatment && 1 === 2" />
      <ProductFrame v-if="type && technology && index" />
      <ProductCustomer
        v-if="(type && technology && index) || order.type_id === 7"
      />
      <ProductDoctor
        v-if="(type && technology && index) || order.type_id === 7"
      />
      <div v-if="(type && technology && index) || order.type_id === 7">
        <div style="margin-top: 20px"><h2>Garantia:</h2></div>
        <q-card>
          <q-card-section>
            <b-form-select
              v-model="warranty"
              :options="warrantyMonths"
              style="background: #f3f6f9"
            ></b-form-select>
          </q-card-section>
        </q-card>
      </div>

      <ImageGallery
        @input="setImage"
        v-if="(type && technology && index) || order.type_id === 7"
      />

      <ProductManufacturerManual v-if="index" @input="setProduct" />
      <div v-if="(type && technology && index) || order.type_id === 7">
        <br />
        <b-button block variant="primary" size="lg" @click="saveCertificate"
          >ENVIAR NOTIFICAÇÃO</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { SET_CREDITS } from '@/core/services/store/auth.module'

export default {
  name: 'NewOrderManual',

  computed: {
    ...mapGetters('orders', { order: 'getNewOrder' }),
    ...mapGetters('menus', {
      colors: 'getColors',
      apis: 'getApis',
      products: 'getProducts',
      indexes: 'getIndexes',
      treatments: 'getTreatments',
      hoops: 'getHoops',
      panels: 'getPanels'
    }),

    warrantyMonths() {
      return [...Array(12).keys()].map((m) => ({
        value: m + 1,
        text: `${m + 1} meses`
      }))
    },

    hasDiopter() {
      return !!this.panels.filter((item) => item.has_diopter).length
    },

    type() {
      return !!this.order.type_id
    },

    technology() {
      return !!this.order.technology_id
    },

    index() {
      return !!this.order.index_id
    },

    color() {
      return !!this.order.color_id
    },

    treatment() {
      return !!this.order.treatment_id
    },
    currentMonth() {
      let date = new Date()
      return date.getMonth() + 1
    },

    currentYear() {
      let date = new Date()
      return date.getFullYear()
    },

    params() {
      return { month: this.month, year: this.year }
    }
  },

  data: () => ({
    id: false,
    merged: false,
    loading: true,
    product: null,
    hasColors: false,
    warranty: 12,
    imageId: null
  }),

  methods: {
    ...mapActions('menus', ['fetchTechnology', 'fetchType', 'resetMenusState']),

    ...mapActions('orders', ['setSendTo', 'fetchOrders', 'resetState']),

    ...mapActions('certificates', ['fetchCertificates']),

    setProduct(product) {
      this.product = JSON.parse(JSON.stringify(product))
    },

    setColorStatus() {
      this.hasColors = true
    },

    setImage(imageId) {
      this.imageId = imageId
    },

    saveCertificate() {
      this.loading = true
      const { newOrder } = JSON.parse(JSON.stringify(this.$store.state.orders))
      let merged = Object.assign({}, newOrder, this.product)
      this.merged = merged

      this.merged.panels = this.$store.state.menus.panels

      this.$http
        .post('certificate/generate/manual', {
          ...merged,
          warranty: this.warranty,
          image: this.imageId
        })
        .then(({ data }) => {
          this.items = data
          if (merged.customer.phone) {
            this.$bvModal.show('whats-1')
          } else {
            this.$alert('Certificado emitido com sucesso!')
            this.$router.push('/dashboard')
          }
          this.fetchOrders(this.params)
          this.fetchCertificates(this.params)

          this.$store.commit(SET_CREDITS)
        })
        .finally(() => (this.loading = false))
    },

    sendByWhatsapp() {
      const suffix = process.env.DEV ? 'local' : 'app'
      const url = `https://api.opticonecta.${suffix}/show`
      const number = this.merged.customer.phone
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '')

      this.$router.push(
        `/dashboard?number=${number}&text=${encodeURI(
          `${url}/${this.items.id}`
        )}`
      )
    },

    reset() {
      this.loading = true
      window.location.reload()
    }
  },

  mounted() {
    this.fetchType({ params: { all: 1 } }).then(() =>
      this.fetchTechnology({ params: { all: 1 } }).finally(
        () => (this.loading = false)
      )
    )
  }
}
</script>
